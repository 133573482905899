

































import { Observer } from "mobx-vue";
import { Component, Vue, Provide, Ref, Inject } from "vue-property-decorator";
import { walletStore } from "@/stores/wallet-store";
import { blockchainHandler } from "@/blockchain";

@Observer
@Component({
  components: {},
})
export default class ConnectButton extends Vue {
  walletStore = walletStore;
  requiredChainId = process.env.VUE_APP_CHAIN_ID;

  get networkName() {
    const { name } = blockchainHandler.getChainConfig(this.requiredChainId);
    return name;
  }
}
